import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Label, Input } from 'dpt-react';

const ResetForm = ({ entity }) => {
  return useObserver(() => (
    <>
      <div>
        <Label htmlFor="new_password_label"/>
        <Input type="password" entity={entity} name="newPassword" placeholder="new_password_label"/>
      </div>

      <div>
        <Label htmlFor="new_repeat_label"/>
        <Input type="password" entity={entity} name="repeatPassword" placeholder="new_repeat_label"/>
      </div>
    </>
  ));
};

export default ResetForm;

