import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';
import { useParams } from 'react-router-dom';

import { useRedirect, useStores } from 'dpt-react/hooks';
import { Spinner } from '../elements';
import Register from './Register';

import styles from '../../assets/styles/activate.module.scss';

const STATES = Object.freeze({
  VALIDATING: 'VALIDATING',
  VALIDATED: 'VALIDATED',
  DECLINED: 'DECLINED'
});

const ActivateInvitedAccount = () => {
  const params = useParams();
  const { authStore } = useStores();
  const [ state, setState ] = useState(STATES.VALIDATING);
  const { redirect } = useRedirect();

  useEffect(() => {
    const validate = async () => {
      const { id, token } = params;
      try {
        const { valid, email } = await authStore.validateActivationToken(id, token);
        setState(valid ? STATES.VALIDATED : STATES.DECLINED);

        if (valid) {
          authStore.account.email = email;
          authStore.account.repeatEmail = email;
        }
      } catch {
        setState(STATES.DECLINED);
      }
    };

    validate();
  }, []);

  const goToLogin = () => {
    redirect('/login');
  };

  return useObserver(() => {
    if (state === STATES.VALIDATING) {
      return (
        <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="validating_invite" />
          </h1>

          <Spinner />
        </section> 
      )
    }

    if (state === STATES.DECLINED) {
      return (
       <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="your_invite_is_expired" />
          </h1>

         <p onClick={goToLogin} className={styles.goToLogin}>
           <FormattedMessage id="return_to_login" />
         </p>
       </section> 
      )
    }

    if (state === STATES.VALIDATED) {
      return (
        <Register inActivation/>
      )
    }
  })
}


export default ActivateInvitedAccount;
