import React from 'react';
import {useObserver} from 'mobx-react-lite';

import { Label, Input } from 'dpt-react';

import settings from '../../settings.js';

const LoginForm = ({ entity }) => {
  return useObserver(() => (
    <>
      <div>
        <Label htmlFor="email_placeholder_label" values={{company: settings.company}}/>
        <Input entity={entity} name="email" placeholder="email_placeholder" values={{company: settings.company}}/>
      </div>

      <div>
        <Label htmlFor="password_placeholder_label" values={{company: settings.company}}/>
        <Input entity={entity} name="password" type="password" placeholder="password_placeholder" values={{company: settings.company}}/>
      </div>
    </>
  ))
} 

export default LoginForm;

