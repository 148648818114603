import 'babel-polyfill';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { useStores } from 'dpt-react/hooks';

import { 
  Login,
  Register,
  Reset,
  ActivateReset,
  Activate,
  DeviceLogin,
  Legal,
  Help,
  ActivateInvitedAccount,
  ActivateDealerInvitedAccount,
  ConfirmEmailChange,
} from './pages';
import { Header, Footer, Aside } from './global';

const Wrapper = observer(({ Component, ...props }) => {
  const { global } = useStores();
  const { lang } = global

    return (
      <>
        <Aside />
        <section id="content">
          <Header />
          <main lang={lang}>
            <Component {...props}/>
          </main>
        </section>
        <Footer />
      </>
    )
})

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Wrapper Component={Login} />} />
      <Route exact path="/login/:location" element={<Wrapper Component={Login} />} />
      <Route exact path="/register" element={<Wrapper Component={Register} />} />
      <Route exact path="/legal" element={<Wrapper Component={Legal} />} />
      <Route exact path="/reset" element={<Wrapper Component={Reset} />} />
      <Route exact path="/help" element={<Wrapper Component={Help} />} />
      <Route exact path="/activate/account/:id/:token" element={<Wrapper Component={ActivateInvitedAccount} />} />
      <Route exact path="/activate_dealer_invite/account/:id/:token" element={<Wrapper Component={ActivateDealerInvitedAccount} />} />
      <Route exact path="/activate/:email/:token" element={<Wrapper Component={Activate} />} />
      <Route exact path="/confirm_email_change/:customer_id/:token" element={<Wrapper Component={ConfirmEmailChange} />} />
      <Route exact path="/reset/:email/:uuid" element={<Wrapper Component={ActivateReset} />} />
      <Route exact path='/proxy/device/login/:token/:access_id' element={<Wrapper Component={DeviceLogin} />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </BrowserRouter>
);

export default Router
