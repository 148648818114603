import React from 'react';
import jwtdecoder from 'jwt-decode';

export const SSO_URL = '';
export const USER_URL = '';
export const URL = '';

export const richTextConfig = {
  ul: chunks => <ul>{chunks}</ul>,
  li: chunks => <li>{chunks}</li>,
  p: chunks => <p>{chunks}</p>,
  div: chunks => <div>{chunks}</div>,
  section: chunks => <section>{chunks}</section>,
  a: chunks => <a>{chunks}</a>,
  figure: chunks => <figure>{chunks}</figure>,
  figcaption: chunks => <figcaption>{chunks}</figcaption>,
  header: chunks => <header>{chunks}</header>,
  footer: chunks => <footer>{chunks}</footer>,
  strong: chunks => <strong>{chunks}</strong>,
  span: chunks => <span>{chunks}</span>
}

export const debounceEvent = (callback, time = 250, interval) => 
  (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };

export const getAccountInfo = () => {
  const value = "; " + document.cookie;
  const parts = value.split('; bc-token=');
  try {
    if (parts.length === 2) { 
      return jwtdecoder(parts.pop().split(";").shift())
    } else {
      throw new Error('no_token_found');
    };

  } catch (e) {
    throw new Error(e)
  };
};

export const supressErrorNames = () => {
  if (process.env.NODE_ENV !== 'production') {
    const originalConsoleError = console.error;
    if (console.error === originalConsoleError) {
      console.error = (...args) => {
        if (args.toString().includes('React Intl')) {
          return;
        }

        originalConsoleError.call(console, ...args);
      };
    }
  }
};

const asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};

export const controlOnErrors = async (entity, validation) => {
  const keys = Object.keys(validation !== undefined ? validation : entity.validation);
  const errors = [];
  let hasErrors = false;

  await asyncForEach(keys, async (key) => {
    const valueToCheck = key.includes('.')
      ? key.split('.').reduce((obj, child) => obj[child], entity)
      : entity[key]


    const result = (validation !== undefined)
      ? await validation[key](valueToCheck)
      : await entity.validation[key](valueToCheck);


    entity[`error${key.replace(/\./g, '')}`] = result;

    if (result.error) {
      errors.push({ key, error: result.message });
      hasErrors = true;
    }
  });

  return new Promise((resolve, reject) => {
    if (hasErrors) {
      reject(errors);
    } else {
      resolve(errors);
    }
  });
};

export const doFetch = async (url, method, body, parseToJson = true) => {
  class ErrorHandler {
    constructor(status, message) {
      this.status = status;
      this.message = message;
    }
  }

  const options = {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'Accept-Bc-Version': 'v2'
    }
  };

  if (body !== null && body !== undefined) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);
  const textResponse = await response.text();

  if (parseToJson) {
    const json = JSON.parse(textResponse);

    if (`${response.status}`.toLowerCase().includes('5') || `${response.status}`.toLowerCase().includes('4')) {
      throw new ErrorHandler(response.status, json.message);
    } else {
      return json;
    }
  } else {
    return textResponse;
  }
};

export const onEnter = (ev, cb) => {
  if (ev.key === 'Enter') {
    cb();
  }
};


export const handleError = (error, onServerError) => {
  onServerError(error?.status || 500);
};

export const wait = s => {
  return new Promise(resolve => {
    setTimeout(resolve, s);
  });
};
