import React from 'react';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';

import { useObserver } from 'mobx-react-lite';
import { translate } from 'dpt-react/i18n';
import { setValue, getValue, getErrorMessage, validate } from './IO';

const InfoInput = props => { 
  const {
    tooltip,
    tooltipValues = {},
    placeholder = '',
    type = 'text',
    disableAutoFill = false,
    className = '',
    ...rest
  } = props;

  const onChange = ev => {
    setValue(props, ev.target.value);
  };

  const onValidate = ev => {
    validate(props, ev.target.value);
  };

  const getStatus = () => {
    const { message, error } = getErrorMessage(props) || {};

    if (error !== undefined && error) {
      return {
        placeholder: message,
        hasError: true
      }
    } else {
      return {
        placeholder: placeholder !== '' ? translate(placeholder) : placeholder,
        hasError: false
      }
    }
  };

  return useObserver(() => { 
    const { hasError, placeholder } = getStatus();

    return (
      <div className="infoInput">
        <input 
          className={`${className} ${hasError && 'invalid'}`}
          type={type}
          autoComplete={disableAutoFill && 'none'}
          onChange={onChange}
          value={getValue(props)}
          onBlur={onValidate}
          placeholder={placeholder}
          {...rest}
        />
        <Tippy 
          content={tooltip !== '' ? <div dangerouslySetInnerHTML={{__html: translate(tooltip, tooltipValues)}}></div> : ''} 
          arrow 
          duration={500} 
          delay={[100, 50]} 
          placement="bottom-end" 
          interactive 
          trigger="mouseenter focus click manual"
        >
          <i 
            className="fas fa-info-circle tooltipped tooltipIcon" 
            data-position="left" 
            data-tooltip={tooltip} 
            tabIndex="-1" 
          />
        </Tippy>
      </div>
    )
  })
}

export default InfoInput;
