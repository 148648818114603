import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';
import { useParams } from 'react-router-dom';

import { useRedirect, useStores, useError } from 'dpt-react/hooks';
import { Spinner, Error } from '../elements';
import { ActivateForm } from '../forms';
import { controlOnErrors, wait } from '../../utils';

import styles from '../../assets/styles/activate.module.scss';

const STATES = Object.freeze({
  VALIDATING: 'VALIDATING',
  VALIDATED: 'VALIDATED',
  DECLINED: 'DECLINED',
  ACTIVATING: 'ACTIVATING',
  REDIRECTING: 'REDIRECTING',
});

const ActiveDealerInvitedAccount = () => {
  const params = useParams();
  const { authStore } = useStores();
  const [state, setState] = useState(STATES.VALIDATING);
  const { redirect } = useRedirect();
  const { error, clearError } = useError('');

  useEffect(() => {
    const validate = async () => {
      const { id, token } = params;
      try {
        const { valid, email } = await authStore.validateActivationToken(id, token);
        setState(valid ? STATES.VALIDATED : STATES.DECLINED);

        if (valid) {
          authStore.account.email = email;
          authStore.account.repeatEmail = email;
        }
      } catch {
        setState(STATES.DECLINED);
      }
    };

    validate();
  }, []);

  const goToLogin = () => {
    redirect('/login');
  };

  const activeAccount = async () => {
    const { account } = authStore;
    clearError();
    await controlOnErrors(account, account.activateValidation)
    const { id, token } = params;
    setState(STATES.ACTIVATING);
    await wait(1000);
    await authStore.activateDealerInvitedAccount(id, token);
    setState(STATES.REDIRECTING);
    await wait(1000);
    window.location.href = window.location.href.replace('sso', 'user');
  };

  return useObserver(() => {
    const { account } = authStore;
    const { activeErrors } = account;

    if (state === STATES.VALIDATING) {
      return (
        <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="validating_invite" />
          </h1>

          <Spinner />
        </section> 
      )
    }

    if (state === STATES.DECLINED) {
      return (
        <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="your_invite_is_expired" />
          </h1>

          <p onClick={goToLogin} className={styles.goToLogin}>
            <FormattedMessage id="return_to_login" />
          </p>
        </section> 
      )
    }

    if (state === STATES.VALIDATED) {
      return (
        <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="complete_your_account_setup" />
          </h1>

          <Error error={error} activeErrors={activeErrors} />

          <ActivateForm dealerActivated entity={account} />

          <div className="btn" onClick={activeAccount}>
            <FormattedMessage id={'activate_your_account'} />
          </div>

          <p onClick={goToLogin} className={styles.goToLogin}>
            <FormattedMessage id="return_to_login" />
          </p>
        </section>
      )
    }

    if (state === STATES.ACTIVATING) {
      return (
        <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="activating_your_account" />
          </h1>

          <Spinner />
        </section>
      )
    }

    if (state === STATES.REDIRECTING) {
      return (
        <section className={styles.page}>
          <h1 className="title">
            <FormattedMessage id="redirecting_to_the_platform" />
          </h1>

          <Spinner />
        </section>
      )
    }
  })
}


export default ActiveDealerInvitedAccount;
