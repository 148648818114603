import { decorate, observable, computed } from 'mobx';
import { Param as param, Validator } from './';
import locales from '../../locales.json';

const getLanguage = () => {
  const lang = navigator.language;
  const activeLocale = locales.find(locale => locale.code === lang);

  if (!activeLocale) {
    switch(lang) {
      case 'en': 
        return 'en-US';
      case 'fr':
        return 'fr-FR';
      case 'de': 
        return 'de-DE';
      case 'nl':
        return 'nl-BE';
      default:
        return 'en-US';
    }
  }

  return activeLocale.code;
};

class User {
  constructor(data) {
    this.validate = new Validator();
    this.email = param(data, 'email');
    this.repeatEmail = param(data, 'repeatEmail');
    this.password = param(data, 'password');
    this.repeatPassword = param(data, 'repeatPassword');
    this.title = param(data, 'title', 1);
    this.firstname = param(data, 'firstname');
    this.lastname = param(data, 'lastname');
    this.locale = param(data, 'locale', getLanguage());
    this.mobile = param(data, 'mobile', '');
    this.acceptTermsOfUse = param(data, 'acceptTermsOfUse', false);
    this.acceptCookiePolicy = param(data, 'acceptCookiePolicy', false);
    this.acceptPrivacyPolicy = param(data, 'acceptPrivacyPolicy', false);

    this.loginValidation = {
      email: input => this.validate.email(input, 'enter_a_valid_email'),
      password: input => this.validate.password(input, 'enter_a_valid_password'),
    };

    this.resetValidation = {
      email: input => this.validate.email(input, 'invalid_email'),
    };

    this.activateValidation = {
      password: input => this.validate.password(input, 'enter_a_valid_password'),
      repeatPassword: () => this.validate.doubleParameter(this.password, this.repeatPassword, 'error_invalid_repeat_password'),
      acceptTermsOfUse: input => this.validate.checkbox(input, 'error_terms_of_use'),
      acceptPrivacyPolicy: input => this.validate.checkbox(input, 'error_privacy_policy'),
      acceptCookiePolicy: input => this.validate.checkbox(input, 'error_cookie_policy'),
    }

    this.validation = {
      ...this.loginValidation,
      repeatEmail: () => this.validate.doubleParameter(this.email, this.repeatEmail, 'error_invalid_repeat_email'),
      repeatPassword: () => this.validate.doubleParameter(this.password, this.repeatPassword, 'error_invalid_repeat_password'),
      title: input => this.validate.undefined(input, 'error_title'),
      firstname: input => this.validate.input(input, 'error_firstname'),
      lastname: input => this.validate.input(input, 'error_lastname'),
      locale: input => this.validate.input(input, 'error_locale'),
      mobile: input => this.validate.input(input, 'error_mobile'),
      acceptTermsOfUse: input => this.validate.checkbox(input, 'error_terms_of_use'),
      acceptPrivacyPolicy: input => this.validate.checkbox(input, 'error_privacy_policy'),
      acceptCookiePolicy: input => this.validate.checkbox(input, 'error_cookie_policy'),
    };

    this.validate.initializeErrors(this, this.validation);
  };

  get activeErrors() {
    const errors = Object.keys(this).filter(key => key.includes('error') && this[key].error);
    return errors.map(error => this[error]);
  };

  resetErrors = () => {
    const errors = Object.keys(this).filter(key => key.includes('error') && this[key].error);

    errors.forEach(key => {
      this[key] = {
        message: '',
        error: false
      }
    });
  }

  toJS = () => {
    return {
      email: this.email,
      repeatEmail: this.repeatEmail,
      repeatPassword: this.repeatPassword,
      firstname: this.firstname,
      lastname: this.lastname,
      mobile: this.mobile,
      locale: this.locale,
      password: this.password,
      title: this.title,
      acceptTermsOfUse: this.acceptTermsOfUse,
      acceptPrivacyPolicy: this.acceptPrivacyPolicy,
      acceptCookiePolicy: this.acceptCookiePolicy,
    }
  }
}

decorate(User, {
  email: observable,
  password: observable,
  repeatPassword: observable,
  title: observable,
  firstname: observable,
  lastname: observable,
  mobile: observable,
  locale: observable,
  repeatEmail: observable,
  acceptTermsOfUse: observable,
  acceptPrivacyPolicy: observable,
  acceptCookiePolicy: observable,
  activeErrors: computed,
  joske: observable
})

export default User;

