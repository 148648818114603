import React, { useEffect } from 'react';
import { Spinner } from '../elements';
import { useParams } from 'react-router-dom';

const DeviceLogin = () => {
  const { token, access_id } = useParams();

  useEffect(() => {
    if (token !== undefined && access_id !== undefined) {
      document.getElementById('root').style.display = 'block';
      const domain = 'bluecherry.io';
      const cookieName = 'device_token';
      const cookieValue = token;
      const myDate = new Date();
      myDate.setMonth(myDate.getMonth() + 11);
      document.cookie = `${cookieName}=${cookieValue};expires=${myDate};domain=${domain};path=/`;
      window.location.href = `https://${access_id}.platform.bluecherry.io`
    } else {
      this.props.history.push('/');
    };
  }, [token, access_id]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1 className="title" style={{ marginTop: '100px' }}>Logging in to your device</h1>
      <Spinner />
    </div>
  )
};

export default DeviceLogin;
