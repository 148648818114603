import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { Label, Input, TextArea } from 'dpt-react';

const HelpForm = ({ entity }) => {
  return useObserver(() => {
    return (
      <>
        <div>
          <Label htmlFor="help_name"/>
          <Input entity={entity} name="name" placeholder="help_name_placeholder" />
        </div>

        <div>
          <Label htmlFor="help_email"/>
          <Input entity={entity} name="email" placeholder="help_email_placeholder" />
        </div>

        <div>
          <Label htmlFor="help_question"/>
          <TextArea entity={entity} name="question" placeholder="help_question_placeholder" />
        </div>
      </>
    )
  })
}

export default HelpForm;

