import React from 'react';
import { useObserver } from 'mobx-react-lite';

import { List, Label, Input, Checkbox } from 'dpt-react'
import { translate } from 'dpt-react/i18n';

import { InputTooltip } from '../elements';
import settings from '../../settings.js';
import locales from '../../locales.json';
import styles from '../../assets/styles/register.module.scss';

class Title {
  constructor(id, title) {
    this.id = id;
    this.title = title;
  }
}

class Locale {
  constructor(data) {
    this.country = data.country;
    this.code = data.code;
  }
}

const RegisterForm = ({ entity, inActivation = false }) => {
  const localesToRender = locales.map(localeData => new Locale(localeData));

  const titles = [
    new Title(1, translate('title_mr')),
    new Title(2, translate('title_ms')),
  ];

  return useObserver(() => (
      <>
        <div className={styles.grid}>
          <div className={styles.title}>
            <div>
              <Label htmlFor="register_title" />
              <List entity={entity} id="register_title" name="title" list={titles} value="id" text="title" />
            </div>

            <div>
              <Label htmlFor="register_firstname" />
              <Input entity={entity} name="firstname" placeholder="register_placeholder_firstname" disableAutoFill/>
            </div>
          </div>

          <div>
            <Label htmlFor="register_lastname" />
            <Input entity={entity} name="lastname" placeholder="register_placeholder_lastname" disableAutoFill/>
          </div>

          <div>
            <Label htmlFor="register_locale" />
            <List entity={entity} name="locale" list={localesToRender} value="code" text="country" />
          </div>

          <div>
            <Label htmlFor="register_mobile" />
            <Input entity={entity} name="mobile" placeholder="register_placeholder_mobile" disableAutoFill/>
          </div>

          <div>
            <Label htmlFor="register_email" />
            <InputTooltip 
              entity={entity} 
              name="email" 
              placeholder="register_placeholder_email" 
              tooltip="register_tooltip_email" 
              tooltipValues={{ company: settings.company }} 
              disableAutoFill 
              disabled={inActivation}
            />
          </div>

          <div>
            <Label htmlFor="register_repeat_email" />
            <InputTooltip entity={entity} name="repeatEmail" placeholder="register_placeholder_repeat_email" tooltip="register_tooltip_repeat_email" disableAutoFill disabled={inActivation}/>
          </div>

          <div>
            <Label htmlFor="register_password" />
            <InputTooltip type="password" entity={entity} name="password" placeholder="register_placeholder_password" tooltip="register_tooltip_password" disableAutoFill/>
          </div>

          <div>
            <Label htmlFor="register_repeat_password" />
            <InputTooltip type="password" entity={entity} name="repeatPassword" placeholder="register_placeholder_repeat_password" tooltip="register_tooltip_repeat_password" disableAutoFill/>
          </div>
        </div>

        <div className="checkbox">
          <Checkbox entity={entity} name="acceptTermsOfUse"/>
          <Label htmlFor="agree_with_the_terms_of_use"/>
        </div>

        <div className="checkbox">
          <Checkbox entity={entity} name="acceptCookiePolicy"/>
          <Label htmlFor="agree_with_the_cookie_policy"/>
        </div>

        <div className="checkbox">
          <Checkbox entity={entity} name="acceptPrivacyPolicy"/>
          <Label htmlFor="agree_with_the_privacy_policy"/>
        </div>
      </>
  ))
}

export default RegisterForm;
