import { decorate, observable } from "mobx";
import { Param as param, Validator } from './';

class Question {
  constructor(data) {
    this.validate = new Validator();
    this.email = param(data, 'email');
    this.name = param(data, 'name');
    this.question = param(data, 'question');

    this.validation = {
      name: input => this.validate.input(input, 'error_footer_name'),
      email: input => this.validate.email(input, 'error_footer_email'),
      question: input => this.validate.input(input, 'error_footer_question')
    };

    this.validate.initializeErrors(this, this.validation);

  };

  toJS = () => {
    return {
      email: this.email,
      name: this.name,
      question: this.question
    };
  };
}

decorate(Question, {
  email: observable,
  name: observable,
  question: observable
});

export default Question;
