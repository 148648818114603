import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Button } from 'dpt-react'
import { useStores, useRedirect, useError } from 'dpt-react/hooks';
import { FormattedMessage } from 'dpt-react/i18n';

import { ActivateReset } from '../forms';
import { controlOnErrors, onEnter, handleError, wait } from '../../utils';
import { Error, Spinner } from '../elements';

import styles from '../../assets/styles/reset.module.scss';

const ActivateResetPage = () => {
  const { authStore } = useStores();
  const [ resetted, setResetted ] = useState(undefined);
  const { error, setError, clearError } = useError('');
  const { redirect } = useRedirect();
  const { password } = authStore;
  const { email, uuid } = useParams();

  const goToLogin = () => {
    redirect('/register');
  };

  const reset = async () => {
    try {
      await controlOnErrors(password);
      await authStore.activateReset(email, uuid);
      clearError();

      setResetted('RESETTING');
      await wait(1000);
      setResetted('REDIRECTING');
      await wait(1000);
      window.location.href = window.location.href.replace('sso', 'user');
    } catch (error) {
      handleError(error, status => {
        if (status === 400) {
          return setError('invalid_password');
        }

        setError('error_occured_in_the_server');
      });
    }
  };

  return useObserver(() => {
    return (
      <>

        {
          resetted === 'RESETTING' && (
            <section className={styles.spinner}>
              <h1 className="title">
                <FormattedMessage id="resetted_password" />
              </h1>

              <Spinner />
            </section>
          )
        }

        {
          resetted === 'REDIRECTING' && (
            <section className={styles.spinner}>
              <h1 className="title">
                <FormattedMessage id="redirecting_to_the_platform" />
              </h1>

              <Spinner />
            </section> 
          )
        }

        {
          resetted === undefined && (
            <section className={styles.form} onKeyDown={ev => onEnter(ev, reset)}>
              <h1 className="title">
                <FormattedMessage id="set_your_new_password" />
              </h1>

              <Error error={error} activeErrors={password.activeErrors}/>

              <ActivateReset entity={password}/>

              <Button text="reset_password" onClick={reset}/>

              <p onClick={goToLogin} className={styles.goToLogin}>
                <FormattedMessage id="return_to_login" />
              </p>
            </section>
          )
        }
      </>
    )
  })
}

export default ActivateResetPage;


