import { observable, decorate } from 'mobx';
import { Account, Password } from '../components/models';
import { SSO_URL, doFetch } from '../utils';

const URLS = {
  ACTIVATE: (email, token) => `${SSO_URL}/api/sso/activate/${email}/${token}`,
  LOGIN: `${SSO_URL}/api/sso/account/login`,
  RESET: `${SSO_URL}/api/sso/account/reset`,
  REGISTER: `${SSO_URL}/api/sso/account/register`,
  ACTIVATE_RESET: `${SSO_URL}/api/sso/account/setpassword`,
  VALIDATE_INVITE: (id, token) => `${SSO_URL}/api/sso/account/validate_activation_token/${id}/${token}`,
  ACTIVATE_INVITE: (id, token) => `${SSO_URL}/api/sso/account/activate_invite/${id}/${token}`,
  ACTIVATE_DEALER_INVITE: (id, token) => `${SSO_URL}/api/sso/account/activate_dealer_invite/${id}/${token}`,
  CONFIRM_EMAIL_CHANGE: (id, token) => `${SSO_URL}/api/sso/account/confirm_email_change/${id}/${token}`
};

class Auth {
  constructor() {
    this.account = new Account();
    this.password = new Password();
  };

  resetAccount = () => {
    this.account.resetErrors();
    this.account = new Account();
  };

  login = async () => {
    const { email, password } = this.account.toJS();
    const bc_app_data = localStorage.getItem('bc_app_data');
    const response = await doFetch(URLS.LOGIN, 'POST', { email, password, bc_app_data });
    // this.resetAccount();
    return response;
  };

  confirmEmailChange = async (id, token) => {
    const response = await doFetch(URLS.CONFIRM_EMAIL_CHANGE(id, token), 'POST', {});
    this.resetAccount();
    return response;
  };

  activateAccount = async (email, token) => {
    const response = await doFetch(URLS.ACTIVATE(email, token), 'POST');
    this.resetAccount();
    return response;
  };

  activateInvitedAccount = async (id, token) => {
    const response = await doFetch(URLS.ACTIVATE_INVITE(id, token), 'POST', this.account.toJS());
    this.resetAccount();
    return response;
  };

  activateDealerInvitedAccount = async (id, token) => {
    const response = await doFetch(URLS.ACTIVATE_DEALER_INVITE(id, token), 'POST', this.account.toJS());
    this.resetAccount();
    return response;
  };

  registerAccount = async () => {
    const response = await doFetch(URLS.REGISTER, 'POST', this.account.toJS());
    this.resetAccount();
    return response;
  };

  validateActivationToken = async (id, token) => {
    return doFetch(URLS.VALIDATE_INVITE(id, token), 'POST');
  };

  sendResetMail = async () => {
    const { email } = this.account.toJS();
    const response = await doFetch(URLS.RESET, 'POST', { email });
    this.resetAccount();
    return response;
  };

  activateReset = (email, uuid) => {
    const { newPassword } = this.password;

    return doFetch(URLS.ACTIVATE_RESET, 'POST', {
      email,
      uuid,
      password: newPassword
    });
  }
};

decorate(Auth, {
  account: observable,
  password: observable
});

export default new Auth();

