export default {
  "footer_company":"Birth watch",
  "title":"Birth watch Platform",
  "header_logo":"birth_watch_logo_header.svg",
  "sidenav_logo":"birth_watch_sidenav_logo.svg",
  "sidenav_text":"<h1> <span>Connect.</span> <strong>Anything.</strong> <span>Anywhere.</span> </h1>",
  "company":"Birth watch",
  "use_proxy":true,
  "show_footer_logo":true

}

