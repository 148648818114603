import React from 'react';
import { Link } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';

import { FormattedMessage } from 'dpt-react/i18n';
import { useWidthValidator, useRedirect } from 'dpt-react/hooks';

import styles from '../../assets/styles/footer.module.scss';
import settings from '../../settings.js';
import logo from '../../assets/images/logo.svg';

const Footer = () => {
  const { redirect } = useRedirect();
  const { isMobile } = useWidthValidator();


  const IoTConnectedLogo = () => {
    return (
      <div className={styles.footerLogo}>
        <img src={logo} alt="bluecherry logo" />
        <strong>BlueCherry IoT Connected</strong>
      </div>
    )
  }

  return useObserver(() => {

    return (
      <>
        {
          !isMobile && (
            <footer className={styles.footer}>
              <span className={styles.flex}> © {new Date().getFullYear()} {settings.footer_company}</span>

              {
                settings.show_footer_logo && (
                  <IoTConnectedLogo />
                )
              }

              {
                !settings.show_footer_logo && (
                  <span className={styles.madewithlove}>
                    <FormattedMessage id="made_with" />
                    <i className="fas fa-heart" />
                    <FormattedMessage id="in_belgium" /> 
                  </span>
                )
              }
            </footer>
          ) 
        }

        {
          isMobile && (
            <footer className={styles.footer}>
              <span className={`${styles.rights} ${styles.flex}`}>
                © {new Date().getFullYear()} {settings.footer_company} 
                {
                  settings.show_footer_logo && (
                    <IoTConnectedLogo />
                  )
                }
              </span>
              <div className={styles.footerRow}>
                <Link to="/legal" className={styles.legal}>
                  <FormattedMessage id="legal_information" />
                </Link>

                <p className={styles.help} onClick={() => redirect('/help')}>
                  <FormattedMessage 
                    id="having_troubles" 
                    values={{
                      strong: (...chunks) => <strong>{chunks}</strong>
                    }}
                  />
                </p>
              </div>

            </footer>
          )
        }
      </>
    )
  })
}

export default Footer;
