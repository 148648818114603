import { observable, decorate } from 'mobx';
import { Param as param, Validator } from './';

class Password {
  constructor(context, data) {
    this.validate = new Validator();
    this.newPassword = param(data, 'newPassword');
    this.repeatPassword = param(data, 'repeatPassword');

    this.validation = {
      newPassword: input => this.validate.input(input, 'new_password_cannot_be_empty'),
      repeatPassword: () => this.validate.doubleParameter(this.newPassword, this.repeatPassword, 'make_sure_your_new_password_matches'),
    };

    this.validate.initializeErrors(this, this.validation);
  }

  get activeErrors() {
    const errors = Object.keys(this).filter(key => key.includes('error') && this[key].error);
    return errors.map(error => this[error]);
  };

  toJS = () => {
    return {
      newPassword: this.newPassword,
      repeatPassword: this.repeatPassword,
    }
  }
}

decorate(Password, {
  newPassword: observable,
  repeatPassword: observable
})

export default  Password;
