import { observable, decorate } from 'mobx';
import { Param as param } from './';

class Document {
  constructor(data) {
    this.id = param(data, 'id');
		this.content = param(data, 'content');
		this.key = param(data, 'key');
		this.lang = param(data, 'lang');
		this.is_default = param(data, 'is_default');
		this.description = param(data, 'description');
		this.company_group = param(data, 'company_group');
  }

  toJS = () => {
    return {
      id: this.id,
			content: this.content,
			key: this.key,
			lang: this.lang,
			is_default: this.is_default,
			description: this.description,
			company_group: this.company_group,
    }
  }
};

decorate(Document, {
  id: observable,
	content: observable,
	key: observable,
	lang: observable,
	is_default: observable,
	description: observable,
	company_group: observable,
});

export default Document;
