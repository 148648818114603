import React, { useEffect, useState } from 'react';
import { useObserver } from "mobx-react-lite";
import { useParams } from 'react-router';
import { FormattedMessage } from 'dpt-react/i18n';
import { Spinner } from '../elements';
import { useStores } from 'dpt-react/hooks';
import { wait } from '../../utils';
import styles from '../../assets/styles/activate.module.scss';

const Activate = () => {
  const { email, token } = useParams();
  const { authStore } = useStores();
  const [ activated, setActivated ] = useState(false);

  useEffect(() => {
    const active = async () => {
      await authStore.activateAccount(email, token);
      await wait(1000);
      setActivated(true);
      await wait(1000);
      window.location.href = window.location.href.replace('sso', 'user');
    };

    active();
  }, [email, token, authStore]);

  return useObserver(() => {
    return (
      <>
        {
          !activated && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="activating_your_account" />
              </h1>

              <Spinner />
            </section>
          )
        }

        {
          activated && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="redirecting_to_the_platform" />
              </h1>

              <Spinner />
            </section>
          )
        }
      </>
    )
  })
}

export default Activate;
