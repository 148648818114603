import React, { useEffect, useState } from 'react';
import { useObserver } from "mobx-react-lite";
import { useParams } from 'react-router';
import { FormattedMessage } from 'dpt-react/i18n';
import { Spinner } from '../elements';
import { Button } from 'dpt-react'
import { useStores, useRedirect } from 'dpt-react/hooks';
import { wait } from '../../utils';
import styles from '../../assets/styles/activate.module.scss';

const VIEWS = {
  CHANGING_EMAIL: 0,
  CHANGED_EMAIL: 1,
  REDIRECTING: 2,
  EXPIRED: 3
};

const ConfirmEmailChange = () => {
  const { authStore } = useStores();
  const { customer_id, token } = useParams();
  const [ view, setView ] = useState(VIEWS.CHANGING_EMAIL);
  const { redirect } = useRedirect();


  useEffect(() => {
    const active = async () => {
      try {
        await authStore.confirmEmailChange(customer_id, token)
        await wait(1000);
        setView(VIEWS.CHANGING_EMAIL);
        await wait(1000);
        window.location.href = window.location.href.replace('sso', 'user');
      } catch (err) {
        setView(VIEWS.EXPIRED);
      }
    };

    active();
  }, [customer_id, token, authStore]);

  const returnToLogin = () => {
    redirect('/');
  };

  return useObserver(() => {
    return (
      <>
        {
          view === VIEWS.CHANGING_EMAIL && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="changing_your_email" />
              </h1>

              <Spinner />
            </section>
          )
        }
        {
          view === VIEWS.CHANGED_EMAIL && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="your_email_has_been_changed" />
              </h1>

              <Spinner />
            </section>
          )
        }

        {
          view === VIEWS.EXPIRED && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="your_token_has_been_expired" />
              </h1>

              <Button text="return_to_login" onClick={returnToLogin}/>
            </section>
          )
        }

        {
          view === VIEWS.REDIRECTING && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="redirecting_to_the_platform" />
              </h1>

              <Spinner />
            </section>
          )
        }
      </>
    )
  })
}

export default ConfirmEmailChange;
