import React from 'react';
import { Link } from 'react-router-dom';

import { FormattedMessage } from 'dpt-react/i18n';
import { useRedirect } from 'dpt-react/hooks';

import settings from '../../settings.js';
import styles from '../../assets/styles/aside.module.scss'


const Aside = () => {
  const { redirect } = useRedirect();
  const tmp = settings;

  return (
    <aside className={styles.aside}>
      <div className={styles.imgContainer}>
        <img src={require(`../../assets/images/${tmp.sidenav_logo}`)} alt="Bluecherry logo" />
      </div>

      <div dangerouslySetInnerHTML={{ __html: settings.sidenav_text }}></div>

      <Link to="/legal" className={styles.legal}>
        <FormattedMessage id="legal_information" />
      </Link>

      <p className={styles.help} onClick={() => redirect('/help')}>
        <FormattedMessage 
          id="having_troubles" 
          values={{
            strong: (...chunks) => <strong>{chunks}</strong>
          }}
        />
      </p>
    </aside>
  )
};

export default Aside;
