import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import settings from './settings.js';
import Router from './components/Router';
import { config } from 'dpt-react';
import { toast } from 'react-toastify';
import packageJson from '../package.json';

import locales from './assets/locales/locales.json';
import specificLocales from './specificLocales.json';
import * as stores from './stores'


import 'dpt-react/dist/index.css';
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/styles/screen.scss';
import 'react-toastify/dist/ReactToastify.min.css';


const Root = () => {
  config.setLocales({...locales, ...specificLocales});
  config.setStores(stores);
  config.setToast(toast);

  if(window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
      });
  }

  useEffect(() => {
    console.log(`UI version: ${packageJson.version}`)
    document.title = settings.title

    document.addEventListener('message', function(e) {
      const message = JSON.parse(e.data);
      alert(message)
      localStorage.setItem('bc_app_data', message.data)
    });
  }, [])

  return (
    <Router />
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
