import React from 'react';
import { FormattedMessage } from 'dpt-react/i18n';
import { useObserver } from "mobx-react-lite"

const Error = ({ error, activeErrors }) => {
  return useObserver(() => (
    <>
      {
        error !== '' && (
          <section className="errorinfo">
            { error }
          </section>
        )
      }

      {
        activeErrors.length > 0 && (
          <section className="errorinfo">
            <h2 className="errortitle">
              <FormattedMessage id="please_resolve_the_following_errors" />
            </h2>
            <ul>
              {
                activeErrors.map(error => (
                  <li>
                    <FormattedMessage id={error.message} />
                  </li>
                ))
              }
            </ul>
          </section>
        )
      }
    </>
  ))
}

export default Error;
