import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';

import { useRedirect, useStores, useError } from 'dpt-react/hooks';
import { useReset } from '../../hooks';
import { RegisterForm } from '../forms';
import { onEnter, controlOnErrors, handleError, wait } from '../../utils';
import { Error, Spinner } from '../elements';

import settings from '../../settings.js';
import styles from '../../assets/styles/register.module.scss';
import { useParams } from 'react-router-dom';

const Register = ({ inActivation = false }) => { 
  const { redirect } = useRedirect();
  const [ activated, setActivated ] = useState(false);
  const { error, setError, clearError } = useError('');
  const [ registered, setRegistered ] = useState(false);
  const { authStore } = useStores(['authStore']);
  const params = useParams();

  useReset(inActivation);

  const goToLogin = () => {
    redirect('/login');
  };

  const register = async () => {
    const { account } = authStore;
    try {
      await controlOnErrors(account);
      setRegistered(true);
      if (inActivation) {
        const { id, token } = params;
        await authStore.activateInvitedAccount(id, token);
        await wait(1000);
        setActivated(true);
        await wait(1000);
        window.location.href = window.location.href.replace('sso', 'user');
      } else {
        await authStore.registerAccount();
      }
      clearError();
    } catch (error) {
      if (!Array.isArray(error)) {
        handleError(error, status => {
          if (status === 400) {
            return setError('invalid_username_or_password');
          }

          setError('error_occured_in_the_server');
        });
      }
    }
  };


  return useObserver(() => {
    const { account } = authStore;
    const { activeErrors } = account;

    return (
      <>
        {
          !registered && (
            <section className={styles.form} onKeyDown={ev => onEnter(ev, register)}>
              <h1 className="title">
                <FormattedMessage id={inActivation ? 'update_account_information' : 'create_account_title'}/>
              </h1>

              <Error error={error} activeErrors={activeErrors} />

              <RegisterForm entity={account} inActivation={inActivation}/>

              <div className="btn" onClick={register}>
                <FormattedMessage id={inActivation ? 'activate_your_account' : 'register_your_account' }/>
              </div>
              <p onClick={goToLogin} className={styles.goToLogin}>
                <FormattedMessage id="return_to_login" />
              </p>
            </section>
          )
        }

        {
          registered && !inActivation && (
            <section className={styles.form} onKeyDown={ev => onEnter(ev, register)}>
              <h1 className="title">
                <FormattedMessage id="control_your_inbox" />
              </h1>

              <p className={styles.info}>
                <FormattedMessage id="created_account_info" values={{
                  company: settings.company
                }}/>
              </p>

              <div className="btn" onClick={goToLogin}>
                <FormattedMessage id="return_to_login" />
              </div>
            </section>
          )
        }

        {
          registered && inActivation && (
            <>
              {
                !activated && (
                  <section className={styles.page}>
                    <h1 className="title">
                      <FormattedMessage id="activating_your_account" />
                    </h1>

                    <Spinner />
                  </section>
                )
              }

              {
                activated && (
                  <section className={styles.page}>
                    <h1 className="title">
                      <FormattedMessage id="redirecting_to_the_platform" />
                    </h1>

                    <Spinner />
                  </section>
                )
              }
            </>
          )
        }

      </>
    )
  }) 
} 

export default Register;

