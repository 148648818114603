import { doFetch } from '../utils';
import { Document } from '../components/models';
import { decorate, observable } from 'mobx';

const URLS = {
  GET_DOCUMENT: (lang, document) => `/api/sso/legal_document/${document}/${lang}`,
  GET_DOCUMENTS: (lang) => `/api/sso/legal_document/${lang}`,
}

class LegalStore {
  constructor() {
    this.documents = [];
  }

  getDocuments = async (lang) => {
    const documents = await doFetch(URLS.GET_DOCUMENTS(lang), 'GET');
    this.documents = documents.map(doc => new Document(doc));
  };

  getDocument = (lang, document) => {
    return doFetch(URLS.GET_DOCUMENT(lang, document), 'GET', null, false)
  }
};

decorate(LegalStore, {
  documents: observable,
})

export default new LegalStore();


