import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';
import { ResetForm } from '../forms';
import { useStores, useRedirect, useError } from 'dpt-react/hooks';
import { useReset } from '../../hooks'
import { controlOnErrors, onEnter, handleError } from '../../utils';
import { Error } from '../elements';
import settings from '../../settings.js';
import styles from '../../assets/styles/reset.module.scss';

const Reset = () => { 
  const { authStore } = useStores();

  const [ hasSent, updateSentState ] = useState(false);
  const { redirect } = useRedirect();
  const { error, setError, clearError } = useError('');

  useReset();

  const goToLogin = () => {
    redirect('/');
  };

  const sendResetMail = async () => {
  const { account } = authStore;
    try {
      await controlOnErrors(account, account.resetValidation);
      await authStore.sendResetMail();
      updateSentState(true);
      clearError();
    } catch (error) {
      handleError(error, () => {
        setError('error_occured_in_the_server');
      });
    }
  };

  return useObserver(() => {
    const { account } = authStore;

    return (
      <>
        {
          !hasSent && (
            <section className={styles.form} onKeyDown={ev => onEnter(ev, sendResetMail)}>
              <h1 className={`title ${styles.title}`}>
                <FormattedMessage id="reset_password_title" />
              </h1>

              <p className={styles.info}>
                <FormattedMessage id="reset_password_info" />
              </p>

              <Error error={error} activeErrors={account.activeErrors}/>
              <ResetForm entity={account}/>

              <div className="btn" onClick={sendResetMail}>
                <FormattedMessage id="send_reset_email" />
              </div>

              <p onClick={goToLogin} className={styles.goToLogin}>
                <FormattedMessage id="return_to_login" />
              </p>
            </section>
          )
        }

        {
          hasSent && (
            <section className={styles.form} onKeyDown={ev => onEnter(ev, goToLogin)}>
              <h1 className={`title ${styles.title}`}>
                <FormattedMessage id="control_your_inbox" />
              </h1>
              <p className={styles.info}>
                <FormattedMessage id="reset_email_sent_info" values={{ email: account.email, company: settings.company}} />
              </p>
              <div className="btn" onClick={goToLogin}>
                <FormattedMessage id="return_to_login" />
              </div>
            </section>
          )
        }
      </>
    )
  })
}

export default Reset;

