import { useObserver } from 'mobx-react-lite';
import { Label, Checkbox } from 'dpt-react';
import { InputTooltip } from '../elements/';
import React from 'react';

const ActivateForm = ({ entity }) => {
  return useObserver(() => {
    return (
      <>
        <div className="grid">
          <div>
            <Label htmlFor="register_password" />
            <InputTooltip type="password" entity={entity} name="password" placeholder="register_placeholder_password" tooltip="register_tooltip_password" disableAutoFill/>
          </div>

          <div>
            <Label htmlFor="register_repeat_password" />
            <InputTooltip type="password" entity={entity} name="repeatPassword" placeholder="register_placeholder_repeat_password" tooltip="register_tooltip_repeat_password" disableAutoFill/>
          </div>
        </div>

        <div className="checkbox">
          <Checkbox entity={entity} name="acceptTermsOfUse"/>
          <Label htmlFor="agree_with_the_terms_of_use"/>
        </div>

        <div className="checkbox">
          <Checkbox entity={entity} name="acceptCookiePolicy"/>
          <Label htmlFor="agree_with_the_cookie_policy"/>
        </div>

        <div className="checkbox">
          <Checkbox entity={entity} name="acceptPrivacyPolicy"/>
          <Label htmlFor="agree_with_the_privacy_policy"/>
        </div>
      </>
    )
  });
};


export default ActivateForm;
