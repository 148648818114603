import React from 'react';
import { Oval } from 'react-loader-spinner'


const Spinner = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    <Oval
      color="#003D6D"
      height={200}
      width={200}
    />
  </div>
);

export default Spinner
