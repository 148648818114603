import React, {useState} from 'react';
import { useObserver } from "mobx-react-lite"

import { FormattedMessage } from 'dpt-react/i18n';
import { useStores, useError, useRedirect } from 'dpt-react/hooks';

import { HelpForm } from '../forms';
import { controlOnErrors, handleError } from '../../utils';

import styles from '../../assets/styles/help.module.scss';

const Help  = () => {
  const { helpStore } = useStores();
  const [ hasSent, setHasSent ] = useState(false);
  const { error, setError, setHtmlError, clearError } = useError('');
  const { redirect } = useRedirect();
  const { question } = helpStore;

  const sendMail = async () => {
    try {
      await controlOnErrors(question)
      await helpStore.sendMail()
      setHasSent(true);
      clearError();
    } catch (error) {
      const onServerError = () => {
        setError('error_occured_in_the_server')
      };

      const onUiError = () => {
        setHtmlError(error);
      };

      handleError(error, onServerError, onUiError);
    }
  };

  const goToLogin = () => {
    redirect('/');
  };

  return useObserver(() => {
    return (
      <>
        {
          !hasSent && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="help" />
              </h1>

              {
                error !== '' && (
                  <div className="errorinfo">
                    { error }
                  </div>
                )
              }

              <HelpForm entity={question}/>

              <div className="btn" onClick={sendMail}>
                <FormattedMessage id="send_help_mail" />
              </div>

              <p className={styles.goToLogin} onClick={goToLogin}>
                <FormattedMessage id="return_to_login" />
              </p>
            </section>
          )
        }

        {
          hasSent && (
            <section className={styles.page}>
              <h1 className="title">
                <FormattedMessage id="sent_email" />
              </h1>


              <p className={styles.info}>
                <FormattedMessage id="sent_email_info" values={{email: question.email}} />
              </p>

              <div className={`btn ${styles.goToLogin}`} onClick={goToLogin}>
                <FormattedMessage id="return_to_login" />
              </div>
            </section>
          )
        }
      </>
    )
  })
};

export default Help;
