import { useEffect } from 'react';
import { useStores } from 'dpt-react/hooks';

export const useReset = (inActivation = false) => {
  const { authStore } = useStores();

  useEffect(() => {
    if (!inActivation) {
      authStore.resetAccount();
    }
  }, []);
};

