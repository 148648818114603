import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'dpt-react/i18n';

import { LoginForm } from '../forms';
import { useStores, useRedirect, useError } from 'dpt-react/hooks';
import { useReset } from '../../hooks'
import { controlOnErrors, onEnter, handleError } from '../../utils';
import { Error } from '../elements';

import styles from '../../assets/styles/login.module.scss';
import settings from '../../settings.js';
import { useLocation } from 'react-router-dom';


const Login = () => {
  const { authStore } = useStores();
  const { error, setError, clearError } = useError('');
  const { redirect } = useRedirect();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search)

  useReset()

  const goToRegister = () => {
    redirect('/register');
  };

  const goToResetPassword = () => {
    redirect('/reset');
  };

  const login = async () => {
    const { account } = authStore;

    try {
      await controlOnErrors(account, account.loginValidation)
      await authStore.login();
      clearError();
      const redirectQuery = queryParams.get('redirect');

      if (redirectQuery !== '' && redirectQuery !== undefined && redirectQuery !== null) {
        window.location.href = `${window.location.protocol}//${redirectQuery}`
        return;
      }

      window.location.href = window.location.href.replace('sso', 'user');
    } catch (error) {
      handleError(error, status => {
        if (status === 401) {
          return setError('invalid_username_or_password');
        }

        setError('error_occured_in_the_server');
      });
    }
  };

  return useObserver(() => {
    const { account } = authStore;
    const tmp = settings;
    return (
      <section className={styles.form} onKeyDown={ev => onEnter(ev, login)}>
        <h1 className="title">
          <FormattedMessage id="login" />
        </h1>

        <div className={styles.mobileLogo}>
          <img src={require(`../../assets/images/${tmp.header_logo}`)} alt="Bluecherry mobile logo" />
        </div>

        <Error error={error} activeErrors={account.activeErrors}/>

        <LoginForm entity={account}/>

        <div className="btn" onClick={login}>
          <FormattedMessage id="Login" />
        </div>

        <div className={`btn ${styles.register}`} onClick={goToRegister}>
          <FormattedMessage id="create_account" />
        </div>

        <p className={styles.resetPassword} onClick={goToResetPassword}>
          <FormattedMessage 
            id="forgot_password" 
            values={{
              strong: (...chunks) => <strong>{chunks}</strong>
            }} 
          />
        </p>

      </section>
    )
  })
}

export default Login;

