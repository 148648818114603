import { decorate, observable } from "mobx";
import { Question } from '../components/models';
import { doFetch, SSO_URL } from '../utils';

const URLS = {
  SEND_HELP_MAIL: `${SSO_URL}/api/sso/supportmail`
}

class HelpStore {
  constructor() {
    this.question = new Question();
  };

  sendMail = () => {
    return doFetch(URLS.SEND_HELP_MAIL, 'POST', this.question.toJS());
  };

  resetQuestion = () => {
    this.question = new Question();
  };
};

decorate(HelpStore, {
  question: observable
});

export default new HelpStore();

